import Store from "../../../../../../Store";
import cs from "./images/cs.png";
import sk from "./images/sk.png";
import de from "./images/de.png";
import it from "./images/it.png";

export const mockImages = {
  cs,
  de,
  sk,
  it,
};

export const getMockImage = () => {
  const short = Store.getUserPlatformLangShort();
  return mockImages[short];
};
