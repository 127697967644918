import screen1 from "../images/newProductPage/czech/1.png";
import screen2 from "../images/newProductPage/czech/2.png";
import screen3 from "../images/newProductPage/czech/3.png";
import screen4 from "../images/newProductPage/czech/4.png";
import screen5 from "../images/newProductPage/czech/5.png";
import screen6 from "../images/newProductPage/czech/6.png";
import screen7 from "../images/newProductPage/czech/7.png";

import Ildiko from "../images/newProductPage/hungarian/people/Ildiko.png";
import Katalin from "../images/newProductPage/hungarian/people/Katalin.png";
import Istvan from "../images/newProductPage/hungarian/people/Istvan.png";
import Zoltan from "../images/newProductPage/hungarian/people/Zoltan.png";
import Gabor from "../images/newProductPage/hungarian/people/Gabor.png";
import Maria from "../images/newProductPage/hungarian/people/Maria.png";

import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";

import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";

import mockup from "../images/mockup_cz.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const slovak = {
  navbar: {
    data: [
      { title: "Objednať kurz", path: "/course" },
      { title: "Prihlásiť sa", path: "" },
      { title: "O platforme eLingo", path: "/product" },
      { title: "O nás", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      hero: {
        languages: [
          "anglicky",
          "španielsky",
          "holandsky",
          "nemecky",
          "taliansky",
          "rusky",
          "portugalsky",
          "poľsky",
        ],
        title: "Hovorte",
        prefix: "",
        sufix: "s istotou!",
        subtitle: `Získajte rýchlo istotu vďaka lekciám zameraným na konverzáciu v reálnom živote.`,
        buttonText: "Objednať teraz!",
      },
      labelInfo: {
        number: "9",
        numberDescription: "jazykov na výber",
        itemTwo: "14 000 hodnotení",
        itemThree: {
          top: "Viac než 200k",
          bottom: "študentov",
        },
      },
      learning: {
        title: "Ako kurz vyzerá?",
        config: [
          {
            category: "Lekcie z reálneho života",
            description:
              "Každá úroveň výučby sa skladá z desiatok lekcií. Ak neviete, na akej ste úrovni - začnite so základmi. Naučte sa pozdravy, dni v týždni, ako si objednať jedlo v reštaurácii. Každá lekcia obsahuje príklady z každodenného života.",
          },
          {
            category: "Cvičenia",
            description:
              "Či už vás lúštenie krížoviek baví denne alebo sa im naopak radšej vyhýbate, nemožno poprieť, že tieto mentálne hádanky výborne trénujú pamäť a umožňujú opakovanie naučenej látky.",
          },
          {
            category: "Slová, frázy a výrazy",
            description:
              "Každá lekcia je sprevádzaná starostlivo vybranými obrázkami, vďaka ktorým je proces učenia intuitívny a kontextuálny. Najčastejšie používané témy pokrývajú širokú škálu slovnej zásoby z každodenného života a cestovania, čo umožňuje komunikovať v týchto oblastiach bez tzv. jazykového bloku.",
          },
          {
            category: "Opýtajte sa odborníka",
            description:
              "Máte problém týkajúci sa jazyka alebo potrebujete pomoc s cvičením? Sme tu, aby sme vám pomohli! Naša funkcia Opýtajte sa odborníka vám dáva možnosť obrátiť sa na odborníka na jazyky, kedykoľvek potrebujete pomoc.",
          },
        ],
        photoData: [
          screen1,
          screen2,
          screen3,
          screen4,
          screen5,
          screen6,
          screen7,
        ],
      },
      languages: {
        languages: [
          { image: new_england, lang: "Angličtina" },
          { image: new_french, lang: "Francúzština" },
          { image: new_spanish, lang: "Španielčina" },
          { image: new_niderland, lang: "Holandčina" },
          { image: new_germany, lang: "Nemčina" },
          { image: new_italy, lang: "Taliančina" },
          { image: new_russia, lang: "Ruština" },
          { image: new_portugal, lang: "Portugalčina" },
          { image: new_polish, lang: "Poľsky" },
        ],
        title: "Dostupné jazyky:",
        description: `Pripravte sa na ďalšiu cestu, doplňte si jazykové znalosti do životopisu alebo sa ponorte do novej kultúry: preskúmajte rozsiahlu zbierku lekcií eLingo a naučte sa, čo potrebujete k tomu, aby ste mohli začať hovoriť, bez ohľadu na úroveň vašich skúseností.`,
      },
      success: {
        title: "Úspešné príbehy",
        subtitle: `Pozrite sa, ako sa ostatní zlepšujú s eLingo`,
        config: [
          {
            title: "Praktické lekcie",
            name: "Katarína",
            comment: `Či už ste začiatočník, ktorý sa práve začína učiť základy, alebo pokročilý študent, ktorý si chce rozšíriť slovnú zásobu, eLingo ponúka niečo pre každého. Jednou z vecí, ktoré sa mi na tejto platforme páčia, je rozmanitosť lekcií, od objednávania jedla v reštaurácii až po objednávanie taxíka, vďaka čomu je učenie realistické a praktické. Okrem toho je veľkým plusom začlenenie fráz z reálneho života a nahrávok pre správnu výslovnosť.`,
            photo: Ildiko,
          },
          {
            title: "Praktické lekcie",
            name: "Mirka",
            comment: `Páči sa mi, že lekcie eLingo sú prispôsobené reálnym situáciám, takže je pre mňa ľahké použiť to, čo som sa naučila, v reálnom svete. Po troch mesiacoch štúdia mi bola ponúknutá nová práca!`,
            photo: Katalin,
          },
          {
            title: "Zaujímavá platforma",
            name: "Jakub",
            comment: `eLingo je vynikajúca platforma na výučbu jazykov. Vďaka rozmanitosti lekcií a cvičení je výučba zaujímavá a pútavá. Vďaka učeniu jazyka som sa cez internet zoznámil s dievčaťom z Nemecka, s ktorou sa čoskoro ožením. Odporúčam 😊`,
            photo: Istvan,
          },
          {
            title: "Používam v práci!",
            name: "Eva",
            comment: `Produkt eLingo používam už niekoľko mesiacov a môžem s istotou povedať, že mi zmenil život. V práci som bol povýšený! Vždy som mal záujem naučiť sa nový jazyk, ale nikdy som na to nemal čas ani prostriedky. S eLingo sa môžem učiť svojím vlastným tempom a podľa svojho vlastného rozvrhu.`,
            photo: Zoltan,
          },
          {
            title: "Príjemné učenie",
            name: "Jirko",
            comment: `Podľa môjho názoru je eLingo úžasná platforma pre každého, kto si chce zlepšiť svoje jazykové znalosti. Platforma je navrhnutá tak, aby sa učenie nového jazyka stalo príjemným zážitkom.`,
            photo: Gabor,
          },
          {
            title: "Používam niekoľko mesiacov",
            name: "Martina",
            comment: `Používam eLingo už niekoľko mesiacov a musím povedať, že pre mňa zmenil pravidlá hry. Ako osoba, ktorá sa vždy zaujímala o učenie nového jazyka, ale nikdy k tomu nemala príležitosť, som sa vďaka eLingo mohla učiť ľahko a pohodlne. Teraz rozumiem ľuďom z rôznych kultúr a dokážem s nimi komunikovať, čo mi rozšírilo rozhľad a otvorilo nové možnosti.`,
            photo: Maria,
          },
        ],
      },
    },
    course: {
      title: "Ako vyzerá kurz?",
      mockupeLingo: mockup,
      right:
        "Na platforme eLingo nenájdete nudné, suché gramatické pravidlá, ktoré si musíte zapamätať za jeden deň. Naučíte sa vety a výrazy tak, ako sa používajú v každodennom živote, a potom si ich upevníte v pamäti pomocou jednoduchých cvičení a opakovania. Kurz bol navrhnutý tak, aby učenie bolo príjemné. Počas kurzu máte možnosť využiť:",
      right2Title: "Lekcie zo skutočného života",
      right2Bottom:
        "Každá úroveň výučby sa skladá z niekoľkých desiatok lekcií. Ak neviete, na akej ste úrovni, začnite so základmi. Naučte sa pozdravy, dni v týždni, ako si objednať jedlo v reštaurácii atď. Každá lekcia obsahuje príklady z každodenného života.",
      commentBlock: {
        title: "Ako užívatelia hovoria:",
        list: [
          {
            name: "Igor",
            comment: `Najúžasnejšia vec na eLingo je, že sa neučíte len slová alebo frázy; učíte sa, ako tieto frázy použiť v kontexte. To je rozdiel, ktorý robí túto platformu tak užitočnou.`,
          },
          {
            name: "Milena",
            comment: `Myslím si, že eLingo je skvelá platforma na učenie sa jazyka. Môžete sa učiť svojím vlastným tempom, čo je pre mňa veľmi dôležité.`,
          },
        ],
      },
    },
  },
  learningWitheLingo: {
    title: "Učenie s eLingo je:",
    cards: [
      {
        icon: learning1,
        headline: "Špičková kvalita",
        text: "Pri vývoji eLingo sa tím odborníkov zameral na jeden najdôležitejší cieľ: aby naša metóda umožnila zvládnuť cudzí jazyk čo najrýchlejšie (bez ohľadu na vek študenta!).",
      },
      {
        icon: learning2,
        headline: "Lekcie bez opustenia domova",
        text: "K používaniu aplikácie eLingo na učenie nepotrebujete nič iné ako telefón, počítač alebo tablet. S eLingo môžete študovať kdekoľvek: vo fronte, v autobuse, a predovšetkým môžete študovať doma a nemusíte nikam chodiť. Učíte sa vo vani alebo pri venčení psa? S programom Euréka je to možné!",
        text2:
          "Učenie s eLingo vám tak ušetrí veľa času stráveného dochádzaním a učenie môžete ľahko zaradiť do svojho rozvrhu!",
      },
      {
        icon: learning3,
        headline: "Krátke, ale systematické lekcie",
        text: "Pri učení jazyka je najdôležitejšia pravidelnosť. Zároveň je pre všetkých z nás ťažké nájsť si na to potrebný čas. S týmto vedomím bola Euréka navrhnutá tak, aby výuka prebiehala v krátkych, ale systematických lekciách.",
      },
    ],
  },
  whyeLingo: {
    title: "Prečo eLingo?",
    textUp:
      "Je to platforma s mnohostranným prístupom k učeniu, ktorá vám pomôže zvládnuť nový jazyk tak, ako sa to stalo s vaším rodným jazykom - prirodzene.",
    textBottom:
      "Učenie sa cudziemu jazyku môže zmeniť život. Nielenže vám otvorí okno do sveta plného profesijných aj osobných príležitostí, ale tiež rozvíja myseľ a posilňuje sebavedomie. S tým, ako sa vďaka technológiám, ako je internet, zmenšuje vzdialenosť a my sa stávame členmi globálnej komunity, sa schopnosť učiť sa jazyk stáva nevyhnutnou súčasťou našej interakcie so svetom a úspechu.",
    cards: [
      {
        headline:
          "Naučte sa frázy a výrazy zo skutočného života pomocou našej pokročilej technológie učenia.",
        text: "Vďaka tisícom grafických a zvukových materiálov uvidíte a budete počuť ako rodení hovoriaci.",
      },
      {
        headline:
          "Získajte istotu v hovorení. Skutočným cieľom učenia jazyka je tiež byť zrozumiteľný.",
        text: "S pomocou eLingo stačí len zopakovať slovnú zásobu, ktorú ste počuli - nebojte sa hovoriť! Náš systém vás opraví.",
      },
      {
        headline:
          "Nedovoľte, aby vám slová unikli z pamäti. Učte sa pomocou rôznych cvičení.",
        text: "Prvky aplikácie sme starostlivo navrhli tak, aby sa vaša myseľ nikdy nenudila. Neustále pripravujeme a pridávame do eLingo nové výzvy, aby bolo učenie zábavné.",
      },
      {
        headline:
          "Ponorte sa do cudzieho jazyka. Snažte sa plne porozumieť novému jazyku na základe znalosti kontextu.",
        text: "Vďaka tisícom grafických a zvukových materiálov uvidíte a budete počuť ako rodení hovoriaci.",
      },
    ],
  },
  aboutUs: {
    hero: {
      title: "Poslanie",
      description:
        "Naším najvyšším cieľom v Verbalii je umožniť každému prístup k osobnému učiteľovi vďaka technológii. Veríme, že jazyk je mostom medzi kultúrami a naším poslaním je prekonávať komunikačné bariéry a zbližovať ľudí prostredníctvom jazykového vzdelávania.",
    },
    why: {
      title: "Prečo my?",
      config: [
        {
          title: "Inovatívne výukové metódy",
          image: dlaczego1,
          description:
            "Náš algoritmus strojového učenia prispôsobuje materiály úrovni každého používateľa, čo zaručuje efektívne a personalizované učenie.",
        },
        {
          title: "Široká ponuka jazykov",
          image: dlaczego2,
          description:
            "Ponúkame kurzy najviac používaných svetových jazykov. To vám umožňuje vybrať si jazyk podľa vašej potreby.",
        },
        {
          title: "Vzdelávanie cez zábavu",
          image: dlaczego3,
          description:
            "Naša platforma robí z učenia jazyka zábavu a zážitok, takže budete motivovaní zostať pri tom.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalizácia a prístup",
          description:
            "Náš výkonný algoritmus strojového učenia neustále pracuje na poskytovaní učebných materiálov na správnej úrovni pre každého jednotlivca. eLingo umožňuje študentom, aby si sami našli vzory, bez toho aby sa museli sústrediť na pravidlá jazyka – rovnakým spôsobom, akým ste sa ako deti naučili svoj materinský jazyk.",
          image: zdjecie1,
        },
        {
          title: "Odvážna kombinácia technológie a vedy",
          description:
            "Náš výkonný algoritmus strojového učenia neustále pracuje na poskytovaní učebných materiálov na správnej úrovni pre každého jednotlivca. eLingo umožňuje študentom, aby si sami našli vzory, bez toho aby sa museli sústrediť na pravidlá jazyka – rovnakým spôsobom, akým ste sa ako deti naučili svoj materinský jazyk.",
          image: zdjecie2,
        },
        {
          title: "Komunita a hodnoty",
          description:
            "Jazyky pomáhajú ľuďom sa zapojiť. Štúdium jazykov podporuje porozumenie. Komunita platformy eLingo združuje ľudí z celého sveta. Sme ambiciózni a stále posúvame hranice. Veríme, že učenie jazykov by malo byť zábavné a sme zvedaví na objavovanie nových kultúr prostredníctvom jazykov.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      titleUp: "Objednať",
      titleDown: "kurz",
      pageLink: "https://granty2050.eu/typ",
      title: "Máte záujem o kurz?",
      title1: "Chcete získať viac informácií?",
      subtitle: "Nechajte nám svoje číslo –",
      subtitle2: "zavoláme vám späť",
      button1: "Meno a priezvisko",
      button2: "Telefónne číslo",
      button3: "E-mail",
      buttonSub: "Žiadosť o zavolanie",
      checkOne:
        "Odoslaním tohto formulára súhlasím s podmienkami a prehlasujem, že som si prečítal/a",
      checkOneTwo: "a",
      checkTwo: "Súhlasím s kontaktovaním pre marketingové účely",
      textOne: "Informujeme vás, že:",
      textTwo:
        "Správcom vašich osobných údajov je EOU Sp. z o.o. so sídlom vo Varšave, Młynarska 42 / 115, 01-171, zapísaná v registri podnikateľov vedenom Okresným súdom vo Varšave pod číslom KRS 0001038089, NIP 5273057993, REGON: 525396900. Vaše osobné údaje budú spracovávané v súvislosti s vybavením vášho dotazu v kontaktnom formulári alebo s plnením uzavretej zmluvy. Tým, že nás kontaktujete, dobrovoľne súhlasíte so spracovaním svojich osobných údajov pre vyššie uvedené účely. V súvislosti s naším spracovaním vašich údajov máte právo na prístup, opravu, vymazanie alebo obmedzenie spracovania, právo vzniesť námietku proti spracovaniu a právo na prenosnosť údajov. Máte tiež právo svoj súhlas kedykoľvek odvolať, ale odvolanie súhlasu nemá vplyv na zákonnosť spracovania vykonaného na základe súhlasu pred jeho odvolaním. Máte tiež právo podať sťažnosť u dozorného úradu. Poskytnutie údajov je samozrejme dobrovoľné, ale môže byť nevyhnutné pre vybavenie dotazu alebo v súvislosti s plnením zmluvy. Osobné údaje budú uchovávané po dobu nevyhnutnú k vyššie uvedeným účelom, nie však po dobu premlčania prípadných nárokov podľa všeobecných právnych predpisov. Starostlivo si prečítajte zásady ochrany osobných údajov.",
      buttonMore: "Prečítajte si viac",
      buttonLess: "Prečítajte si menej",
      subOne: "podmienky",
      subTwo: "a pravidlá ochrany údajov.",
      buttonTXT: "Ďakujeme",
      slovakLead: "sk",
      buttonText:
        "Jeden z našich konzultantov sa vám ozve počas nasledujúcich pracovných dní.",
      buttonText2: "Budeme v kontakte!",
      privacyLink: "/Privacy_SK.pdf",
      rulesLink: "/Regulations_SK.pdf",
      product: "es",
      market: "sk",
      send_to_url: "https://crm.europa-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_Lexicos",
      product_variant: "sk-en",
      server_link: "https://cms.europa-assets.eu/integration/lead-from-landing",
      currency: "eur",
      price: 57,
    },
  },
  footer: {
    privacy: "Ochrana údajov",
    privacyLink: "/Privacy_SK.pdf",
    rules: "Podmienky služby",
    rulesLink: "/Regulations_SK.pdf",
  },
};
