import React, { useContext, useEffect, useState } from "react";
import hungaryFlag from "../../product/images/languages/dymek_wegry.png";
import czechFlag from "../../product/images/languages/czeski_o.png";
import slovakFlag from "../../product/images/languages/slowacki_o.png";
import germanFlag from "../../product/images/languages/dymek_niemcy.png";
import polishFlag from "../../product/images/languages/dymek_polski.png";
import italianFlag from "../../product/images/languages/dymek_wlochy.png";

import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Store from "../../Store";
import Dropdown from "react-dropdown";
import { LanguageContext } from "../contexts/LanguageContext";
import "./navbarStyles.css";

export const LanguageChanger = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { lang, setLang } = useContext(LanguageContext);

  const options = [
    {
      value: "cs",
      label: (
        <div className="Dropdown-items navAnimation">
          <img width={35} src={czechFlag} alt="Čeština"></img>
          <p style={{ fontSize: "16px" }} className="dontshow">
            {" "}
            Čeština
          </p>
        </div>
      ),
    },
    {
      value: "sk",
      label: (
        <div className="Dropdown-items navAnimation">
          <img width={35} src={slovakFlag} alt="Slovenčina"></img>
          <p style={{ fontSize: "16px" }} className="dontshow">
            {" "}
            Slovenský
          </p>
        </div>
      ),
    },
    {
      value: "de",
      label: (
        <div className="Dropdown-items navAnimation">
          <img width={35} src={germanFlag} alt="Deutsch"></img>
          <p style={{ fontSize: "16px" }} className="dontshow">
            {" "}
            Deutsch
          </p>
        </div>
      ),
    },
    {
      value: "it",
      label: (
        <div className="Dropdown-items navAnimation">
          <img width={35} src={italianFlag} alt="Italiano"></img>
          <p style={{ fontSize: "16px" }} className="dontshow">
            {" "}
            Italiano
          </p>
        </div>
      ),
    },
    {
      value: "pl",
      label: (
        <div className="Dropdown-items navAnimation">
          <img width={35} src={polishFlag} alt="Polski"></img>
          <p style={{ fontSize: "16px" }} className="dontshow">
            {" "}
            Polski
          </p>
        </div>
      ),
    },
  ];

  const handleSelect = ({ value }) => {
    const newLang = value;
    setLang(newLang);
    Store.setUserLanguage(newLang);
    const pathParts = pathname.split("/");
    pathParts[1] = newLang;
    navigate(pathParts.join("/"));
  };

  return (
    <div>
      <Dropdown
        options={options}
        onChange={handleSelect}
        value={lang}
        placeholder="Select an option"
        controlClassName={"mySuperClass"}
      />
    </div>
  );
};
