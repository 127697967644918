import { faEye, faEyeSlash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router";
import api from "../../../api/api";
import Store from "../../../../Store";

import HomepageButton from "../../Buttons/HomepageButton/HomepageButton";
import "./RegisterModal.css";

function RegisterModal(props) {
  const navigate = useNavigate();
  const [inputEmail, setInputEmail] = useState("");
  const [inputUsername, setInputUsername] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [inputPasswordShow, setInputPasswordShow] = useState(false);
  const [inputPasswordRepeat, setInputPasswordRepeat] = useState("");
  const [inputPasswordRepeatShow, setInputPasswordRepeatShow] = useState(false);
  const [inputKey, setInputKey] = useState("");

  const [isPasswordMatch, setIsPasswordMatch] = useState(true);

  const login = async (email, password) => {
    const res = await api.post("/auth/login", {
      email: email,
      password: password,
    });

    const token = res.data.token;
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    Store.setToken(token);
    window.gtag("event", "login");

    // navigate("/select-language");
  };

  const handleRegisterFormSubmit = async (ev) => {
    ev.preventDefault();
    try {
      if (isPasswordMatch) {
        await api.post("/user/register", {
          email: inputEmail.trim(),
          password: inputPassword,
          accessKey: inputKey,
          username: inputUsername,
        });

        await login(inputEmail, inputPassword);
        navigate("/platform?afterRegister=1");
      }
      window.gtag("event", "register");
    } catch (err) {
      props.setError(err.message);
    }
  };

  const handlePasswordBlur = () => {
    setIsPasswordMatch(
      inputPasswordRepeat !== "" && inputPassword !== ""
        ? inputPassword === inputPasswordRepeat
        : true
    );
  };

  return (
    <div
      className={
        "register-modal " +
        (props.isModalOpen ? " register-modal--active " : " ")
      }
    >
      <div
        className="register-modal__closing-div"
        onClick={() => props.setIsModalOpen(false)}
      ></div>
      <div className={"register-modal-box "}>
        <div>
          <div className="register-modal__buttons-wrapper">
            <button type="button" className="register-modal__info-button">
              ?
              <div className="register-modal__info-text-wrapper">
                <p className="register-modal__info-text">
                  {Store.getText("contactText")}
                  <span className="register-modal__span-block">
                    {Store.getText("contactEmail")}
                  </span>
                </p>
              </div>
            </button>
            <button
              type="button"
              className="register-modal__closing-button"
              onClick={() => props.setIsModalOpen(false)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <form
            className="register-modal__form"
            onSubmit={handleRegisterFormSubmit}
            autoComplete="on"
          >
            <h2 className="register-modal__form-title">
              {Store.getText("registerButtonText")}
            </h2>
            <p className="register-modal__form-slogan register-modal__form-slogan--login">
              {Store.getText("registerSlogan")}
            </p>
            <div className="register-modal__main-form-content">
              <div className="register-modal__input-wrapper">
                <label className="register-modal__input-label">
                  {Store.getText("activationCodeLabel")}
                </label>
                <input
                  className="register-modal__input"
                  type="text"
                  onChange={(ev) => setInputKey(ev.target.value)}
                  value={inputKey}
                ></input>
              </div>
              <div className="register-modal__input-wrapper">
                <label className="register-modal__input-label">
                  {Store.getText("emailLabel")}
                </label>
                <input
                  className="register-modal__input"
                  type="email"
                  onChange={(ev) =>
                    setInputEmail(ev.target.value.toLowerCase())
                  }
                  value={inputEmail}
                ></input>
              </div>

              <div className="register-modal__input-wrapper">
                <label className="register-modal__input-label">
                  {Store.getText("username")}
                </label>
                <input
                  className="register-modal__input"
                  type="text"
                  onChange={(ev) => setInputUsername(ev.target.value.trim())}
                  value={inputUsername}
                ></input>
              </div>
              <div className="register-modal__input-wrapper">
                <label className="register-modal__input-label">
                  {Store.getText("passwordLabel")}
                </label>
                <div className="register-modal__password-wrapper">
                  <input
                    className="register-modal__input"
                    type={inputPasswordShow ? "text" : "password"}
                    onChange={(ev) => setInputPassword(ev.target.value)}
                    value={inputPassword}
                    onBlur={handlePasswordBlur}
                  ></input>
                  <button
                    className="register-modal__password-icon-button"
                    type="button"
                    title={
                      inputPasswordShow
                        ? Store.getText("hidePassword")
                        : Store.getText("showPassword")
                    }
                    onClick={() => setInputPasswordShow(!inputPasswordShow)}
                  >
                    {inputPasswordShow ? (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    ) : (
                      <FontAwesomeIcon icon={faEye} />
                    )}
                  </button>
                </div>
              </div>
              <div></div>
              <div className="register-modal__input-wrapper">
                <label className="register-modal__input-label">
                  {Store.getText("repeatPasswordLabel")}
                </label>
                <div className="register-modal__password-wrapper">
                  <input
                    className="register-modal__input"
                    type={inputPasswordRepeatShow ? "text" : "password"}
                    onChange={(ev) => setInputPasswordRepeat(ev.target.value)}
                    onBlur={handlePasswordBlur}
                    value={inputPasswordRepeat}
                  ></input>
                  <button
                    className="register-modal__password-icon-button"
                    type="button"
                    title={
                      inputPasswordRepeatShow
                        ? Store.getText("hidePassword")
                        : Store.getText("showPassword")
                    }
                    onClick={() =>
                      setInputPasswordRepeatShow(!inputPasswordRepeatShow)
                    }
                  >
                    {inputPasswordRepeatShow ? (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    ) : (
                      <FontAwesomeIcon icon={faEye} />
                    )}
                  </button>
                </div>
                {isPasswordMatch ? (
                  ""
                ) : (
                  <p className="register-modal__alert">
                    {Store.getText("passwordsNotMatchAlert")}
                  </p>
                )}
              </div>
            </div>
            <HomepageButton
              type="submit"
              style={{
                backgroundColor: "#97AD45",
              }}
            >
              {Store.getText("registerButtonText")}
            </HomepageButton>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RegisterModal;
