import { faEye, faEyeSlash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import api from "../../../api/api";
import Store from "../../../../Store";

import HomepageButton from "../../Buttons/HomepageButton/HomepageButton";
import "./LoginModal.css";
import { Link } from "react-router-dom";

function LoginModal(props) {
  const navigate = useNavigate();
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [inputPasswordShow, setInputPasswordShow] = useState(false);
  const { lang } = useParams();

  const login = async (email, password) => {
    const res = await api.post("/auth/login", {
      email: email,
      password: password,
    });

    const token = res.data.token;
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    Store.setToken(token);

    navigate("/platform");
  };

  const handleLoginFormSubmit = async (ev) => {
    ev.preventDefault();
    try {
      await login(inputEmail, inputPassword);
    } catch (err) {
      props.setError(err.message);
    }
  };
  return (
    <div
      className={
        "login-modal " + (props.isModalOpen ? " login-modal--active " : " ")
      }
    >
      <div
        className="login-modal__closing-div"
        onClick={() => props.setIsModalOpen(false)}
      ></div>
      <div className={"login-modal-box "}>
        <div>
          <div className="login-modal__buttons-wrapper">
            <button type="button" className="login-modal__info-button">
              ?
              <div className="login-modal__info-text-wrapper">
                <p className="login-modal__info-text">
                  {Store.getText("contactText")}
                  <span className="login-modal__span-block">
                    {Store.getText("contactEmail")}
                  </span>
                </p>
              </div>
            </button>
            <button
              type="button"
              className="login-modal__closing-button"
              onClick={() => props.setIsModalOpen(false)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <form
            className="login-modal__form"
            onSubmit={handleLoginFormSubmit}
            autoComplete="on"
          >
            <h2 className="login-modal__form-title">
              {Store.getText("loginButtonText")}
            </h2>
            <p className="login-modal__form-slogan login-modal__form-slogan--login">
              {Store.getText("loginSlogan")}
            </p>
            <div className="login-modal__input-wrapper">
              <label className="login-modal__input-label">
                {Store.getText("emailLabel")}
              </label>
              <input
                className="login-modal__input"
                type="email"
                onChange={(ev) => setInputEmail(ev.target.value)}
                value={inputEmail}
              ></input>
            </div>
            <div className="login-modal__input-wrapper">
              <label className="login-modal__input-label">
                {Store.getText("passwordLabel")}
              </label>
              <div className="login-modal__password-wrapper">
                <input
                  className="login-modal__input"
                  type={inputPasswordShow ? "text" : "password"}
                  onChange={(ev) => setInputPassword(ev.target.value)}
                  value={inputPassword}
                ></input>
                <button
                  className="login-modal__password-icon-button"
                  type="button"
                  title={
                    inputPasswordShow
                      ? Store.getText("hidePassword")
                      : Store.getText("showPassword")
                  }
                  onClick={() => setInputPasswordShow(!inputPasswordShow)}
                >
                  {inputPasswordShow ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </button>
              </div>
              <div className="login-modal__recover-password-text">
                {Store.getText("passwordRecoveryLoginText")}{" "}
                <Link
                  className="login-modal__recover-password-text--link"
                  to={`/${lang}/password-recovery`}
                  onClick={() => props.setIsModalOpen(false)}
                >
                  {Store.getText("passwordRecoveryLoginLink")}
                </Link>
                .
              </div>
            </div>
            <HomepageButton
              type="submit"
              style={{
                backgroundColor: "#97AD45",
              }}
            >
              {Store.getText("loginButtonText")}
            </HomepageButton>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginModal;
