import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";
import happyWoman from "./images/happy_woman.png";
import happyWomanMobile from "./images/happy-woman-mobile.webp";
import { useNavigate } from "react-router";

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  margin: 80px 0 40px 0;

  @media (max-width: 1390px) {
    margin: 40px 40px;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 0px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h1`
  color: ${Colors.mainColor};
  font-weight: bold;
  text-align: center;
  font-size: 52px;
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 550px;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    font-size: 36px;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 0px;
    width: 100%;
  }
`;

const Subtitle = styled.div`
  color: ${Colors.mainColor};
  font-size: 16px;
  text-align: center;
  margin-top: 30px;
  max-width: 400px;

  @media (max-width: 600px) {
    font-size: 20px;
    margin: 0px;
  }
`;

const ImageWrapper = styled.div`
  max-width: 1200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;

  @media (max-width: 1390px) {
    display: none;
  }
`;

const ImageWrapperMobile = styled.div`
  width: 300px;
  height: 300px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 50%;

  @media (min-width: 1390px) {
    display: none;
    font-size: 20px;
    margin: 0px;
  }
`;

const CustomLinkButton = styled.div`
  display: flex;
  padding: 12px 50px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: ${Colors.lightGreen};
  color: ${Colors.white};
  cursor: pointer;
  position: relative;
  text-decoration: none;
  font-weight: 800;
  transition: 0.35s ease;
  font-size: 24px;
  margin-top: 64px;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 900px;
  justify-content: left;

  @media (min-width: 1390px) {
    margin-left: -200px;
  }

  @media (max-width: 1390px) {
    margin-left: 0;
    justify-content: center;
    width: 500px;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Language = styled.div`
  text-align: left;
  color: ${Colors.lightGreen};
  max-width: fit-content;
  overflow: hidden;
  border-right: 3px solid ${Colors.mainColor};
  white-space: nowrap;
  animation: typing 4s steps(50, end), blink-caret 0.5s step-end infinite;

  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: ${Colors.mainColor};
    }
  }
`;

const Hero = ({ data }) => {
  const [lang, setLang] = useState(data.languages[0]);
  const navigate = useNavigate();

  const randLanguage = () => {
    let random = Math.floor(Math.random() * data.languages.length);
    setLang(() => data.languages[random]);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      randLanguage();
    }, 5000);

    return () => clearInterval(interval);
  });

  return (
    <Wrapper>
      <ContentWrapper>
        <TextWrapper>
          <Title>
            {data.title} {data.prefix}{" "}
            {data.languages.map(
              (l) => l === lang && <Language key={l}> {l} </Language>
            )}
          </Title>
          <Title>{data.sufix}</Title>
          <Subtitle>{data.subtitle}</Subtitle>
        </TextWrapper>
      </ContentWrapper>
      <div></div>
      <ImageWrapper>
        <img
          style={{ height: "auto", width: "100%" }}
          src={happyWoman}
          alt="happy woman"
          draggable={false}
        ></img>
      </ImageWrapper>
      <ImageWrapperMobile>
        <img
          style={{ height: "100%", width: "auto" }}
          draggable={false}
          src={happyWomanMobile}
          alt="happy woman"
        ></img>
      </ImageWrapperMobile>
    </Wrapper>
  );
};

export default Hero;
