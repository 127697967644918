import { faEye, faEyeSlash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router";
import api from "../../../api/api";
import Store from "../../../../Store";

import HomepageButton from "../../Buttons/HomepageButton/HomepageButton";
import "./PasswordRecoveryModal.css";
import SecondaryButton from "../../Buttons/SecondaryButton/SecondaryButton";
import { Link } from "react-router-dom";

function PasswordRecoveryModal(props) {
  const [inputEmail, setInputEmail] = useState("");
  const [isRecoveryStarted, setIsRecoveryStarted] = useState(false);

  const recover = async (email) =>
    api
      .post("/auth/password-recovery", {
        email: email,
      })
      .then(() => setIsRecoveryStarted(true));

  const handleReset = async (ev) => {
    ev.preventDefault();
    try {
      await recover(inputEmail);
    } catch (err) {
      props.setError(err.message);
    }
  };

  return (
    <div
      className={
        "password-recovery-modal " +
        (props.isModalOpen ? " password-recovery-modal--active " : " ")
      }
    >
      <div
        className="password-recovery-modal__closing-div"
        onClick={() => props.setIsModalOpen(false)}
      ></div>
      <div className={"password-recovery-modal-box "}>
        <div>
          <div className="password-recovery-modal__buttons-wrapper">
            <button
              type="button"
              className="password-recovery-modal__info-button"
            >
              ?
              <div className="password-recovery-modal__info-text-wrapper">
                <p className="password-recovery-modal__info-text">
                  {Store.getText("contactText")}
                  <span className="password-recovery-modal__span-block">
                    {Store.getText("contactEmail")}
                  </span>
                </p>
              </div>
            </button>
            <button
              type="button"
              className="password-recovery-modal__closing-button"
              onClick={() => props.setIsModalOpen(false)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <form
            className="password-recovery-modal__form"
            onSubmit={handleReset}
            autoComplete="on"
          >
            <h2 className="password-recovery-modal__form-title">
              {Store.getText("passwordRecoveryHeader")}
            </h2>
            <p className="password-recovery-modal__form-slogan password-recovery-modal__form-slogan--login">
              {Store.getText("passwordRecoveryDescription")}
            </p>
            <div className="password-recovery-modal__input-wrapper">
              <label className="password-recovery-modal__input-label">
                {Store.getText("passwordRecoveryEmail")}
              </label>
              <input
                className="password-recovery-modal__input"
                type="email"
                onChange={(ev) => setInputEmail(ev.target.value)}
                value={inputEmail}
              ></input>
            </div>

            <HomepageButton
              type="submit"
              disabled={isRecoveryStarted}
              style={{
                backgroundColor: "#97AD45",
              }}
            >
              {isRecoveryStarted
                ? Store.getText("passwordRecoveryButtonAfterSent")
                : Store.getText("passwordRecoveryButton")}
            </HomepageButton>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PasswordRecoveryModal;
