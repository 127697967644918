import Store from "../../../Store";
import getLanguageMap from "./getLanguageMap";

const languageFunFacts = {
  cs: {
    en: [
      "Londýnské metro, vůbec nejstarší metro na světě, je světem sám pro sebe. Londýňané mu říkají „tube“ – „roura“. Běžný anglický výraz pro metro zní „underground“, tedy podzemka.",
      "Angličané vypijí nejvíce čaje na světě, skoro třikrát více než Japonci a 22x více než např. Francouzi.",
      "Hlavní město Anglie, Londýn se dříve jmenovalo Londonium, Ludenwic, Ludenburg",
      "V Anglii žije více než 30.000 lidí se jménem John Smith.",
    ],
    es: [
      "Španělština je po čínštině druhým nejrozšířenějším jazykem na světě. Celkově jím mluví po celém světě více než 406 milionů lidí.",
      "Lízátka Chupa Chups jsou světově proslulá a vynalezena byla Španělem Enricem Bernatem v roce 1958. Původně se měla jmenovat „Gol“, tento název se ale neuchytil. Následně dostala lízátka název „Chups“.",
      "Nejstarší restaurace na světě se nachází v Madridu a je otevřena od roku 1725. Její název je Sobrino de Botin a najíte se tam levněji než v Praze.",
      "Nejstarší existující maják na světě je ve Španělsku a nazývá se Herkulova věž. Byl postaven v prvním století a je stále funkční. Najdete ho v oblasti Galicie, v blízkosti města Santiago de Compostela.",
    ],
    de: [
      "Hlavní město Berlín je 9krát větší než Paříž a má mnohem víc mostů než Benátky. Pyšní se také největší vlakovou stanicí v Evropě.",
      "O tom, že Němci jsou „hlavičky“, není pochyb. Vymysleli automatickou kalkulačku, automobil, benzín, inzulín, kapesní hodinky, televizi, parafín, benzínové a dieselové motory a to, co máme nejradši – gumové medvídky!",
      "Německé úřady mají při výběru jména pro vaše dítě poslední slovo. Zvláštní jména, která neindikují pohlaví (např. Matti), anebo příjmení místo prvního jména, schválena nebudou.",
      "Německo je první zemí, která ustanovila tzv. Daylight saving time, tedy letní čas. Přešla na něj už v roce 1916 kvůli úspoře elektrické energie během 1. světové války.",
    ],
    fr: [
      "Nejnavštěvovanější atrakce v Paříži není Eiffelova věž, ani Louvre, ale Disneyland.",
      "Podle francouzského práva můžete vstoupit do manželství s mrtvým člověkem. Je třeba splnit určitá pravidla, jako třeba, že dotyčný to měl za svého života v plánu.",
      "Ve Francii naleznete nejvyšší horu Evropy - Mont Blanc (4810m).",
      "Bikiny pocházejí taktéž z Francie, původní název zněl Atom, poté se přejmenovaly podle ostrova jménem Bikini, který dříve sloužil k testování atomové bomby.",
    ],
    it: [
      "V Itálii jsou dva nezávislé státy v rámci jedné země: Republika San Marino (40 čtverečních km) a Vatikán (108,7 akrů).",
      "Žádná jiná země v Evropě nemá tolik sopek jako Itálie. Je to způsobeno tím, že italský poloostrov stojí na zlomové linii. Tři hlavní sopky, Etna, Stromboli a Vesuv byly aktivní v posledních 100 letech.",
      "Mnoho italských dětí žije u svých rodičů až do svých 30 let, a to i v případě, že mají práci. Italská rodina je považována za samé srdce italské společnosti.",
      "Ve střední Itálii je kašna, ze které teče červené víno 24 hodin denně. Víno je zdarma pro všechny, kromě opilců a hulvátů.",
    ],
    nl: [
      "Holanďané jsou nejvyšší na světě. Možná je to kvůli časté konzumaci mléčných výrobků.",
      "Hollandse nieuwe, aneb syrový sleď s cibulí je jedním z holandských jídel. Sice nevypadá moc lákavě, ale třeba vás překvapí.",
      "Holandsko je název středozápadní části Nizozemí a někdy se nesprávně používá jako název celé země.",
      "V Nizozemí mají největší spotřebu kávy na obyvatele na světě, v průměru 2,4 šálků kávy na osobu denně.",
    ],
    ru: [
      "Rusko je větší než Pluto. Pluto má 16,6 milionů km čtverečních a Rusko 17.",
      "V Rusku je nejméně 15 tajných měst. Nejsou tam cedule, dopravní značky a do jejich blízkosti nepustí žádného cizince. Skoro nikdo nezná jejich názvy ani přesnou lokalitu.",
      "25 % Rusů umírá před dosažením věku 55 let, ve srovnání s pouhým 1 % v USA. Na vině je vodka.",
      "Bohatí Rusové si místo taxíku berou sanitky, aby se vyhnuli moskevskému provozu.",
    ],
    pl: [
      "Více než třetina Poláků nežije v Polsku. Přestože má Polsko okolo 38 milionů obyvatel, k polské národnosti se hlásí až 60 milionů lidí na celém světě.",
      "Pokud mohou být Poláci na někoho hrdí, tak potom určitě na osobnosti, které se mohou pyšnit získáním prestižní Nobelovy ceny. Je tak opravdu úctyhodné, že ji za celou dobu své existence získalo dohromady 17 osobností!",
      "V Polsku se nachází více než 7 tisíc jezer.",
      "Většina elektřiny v Polsku je vyrobená v tepelných elektrárnách, kde dochází ke spalování uhlí.",
    ],
  },

  sk: {
    en: [
      "Nalepené známky na pohľadnici hore nohami, na ktorých je vyobrazená britská kráľovná, môže byť považované za trestný čin. ",
      "Angličania vypijú najviac čaju na svete, skoro 3x viac ako Japonci a 22x viac ako napr. Francúzi",
      "Väčšina ľudí si myslí, že šampanské vynašli Francúzi, ale technika, ktorú používal Dom Perignon, existovala už v Anglicku, a tak sa Angličania stali vynálezcami šumivého vína. Na zdravie!",
      "Približne 1 miliarda ľudí na celom svete hovorí po anglicky. Je to najglobálnejší jazyk na svete.",
    ],
    es: [
      "V Španielsku majú zaujímavú silvestrovskú tradíciu, podľa ktorej, každý kto zje počas silvestrovskej polnoci s každým úderom vežových hodín jednu guľôčku hroznového vína, bude mať v nasledujúcom roku šťastie.",
      "Španielsko nebolo súčasťou ani prvej, ani druhej svetovej vojny",
      "Španielčina je druhý najpoužívanejší jazyk na svete.",
      "Španielsko bola prvá krajina Európy, ktorá zakázala fajčenie na pracoviskách a v baroch. Stalo sa tak v roku 2006.",
    ],
    de: [
      "Nemecko patrí k popredným knižným krajinám na svete – ročne vydá približne 94 000 titulov. Nachádza sa tu aj najvýznamnejšie podujatie medzinárodného knižného vydavateľstva, Medzinárodný knižný veľtrh vo Frankfurte.",
      "Nemecké zákony zakazujú mená, ktoré neoznačujú pohlavie alebo používajú rodinné meno ako krstné meno. V roku 2014 boli najpopulárnejšími detskými menami Sophie/Sofie pre dievča a Maximilián pre chlapca.",
      "Nemecké zákony zakazujú mená, ktoré neoznačujú pohlavie alebo používajú rodinné meno ako krstné meno. V roku 2014 boli najpopulárnejšími detskými menami Sophie/Sofie pre dievča a Maximilián pre chlapca.",
      "V krajine Mníchov sa pravidelne každý rok koná festival Oktoberfest, najväčší festival na svete. Festival, ktorý bez problémov pokračuje od roku 1810, sa začína posledný septembrový týždeň a končí sa prvý októbrový týždeň.",
    ],
    fr: [
      "V Afrike žije viac ľudí hovoriacich po francúzsky ako v samotnom Francúzsku.",
      "Francúzski novomanželia po vstupe do manželstva takmer nikdy nežijú so svojimi rodičmi. To je vo francúzskej kultúre nezmysel, pretože mladá rodina by mala urobiť všetko pre to, aby sa presťahovala do vlastného priestoru.",
      "Volanie o pomoc May Day nepochádza z angličtiny, ale z francúzskeho m’aide, v preklade pomôžte mi.",
      "Francúzi sa nepozerajú iba na najnovšie výstrelky, ale milujú nákupy z druhej ruky a kupujú použitý tovar. Ľudia vo Francúzsku majú úplne normálny postoj k používaniu vecí, ktoré sú z druhej ruky a nemajú problém kúpiť si interiérové doplnky, ktorý pred nimi použil niekto iný.",
    ],
    it: [
      "Viac ako tretina slobodných talianskych mužov žije stále so svojimi rodičmi. Týka sa to 30-35 ročných mužov.",
      "Najdlhší tunel na svete prepája Taliansko a Švajčiarsko. Gotthard tunnel meria 57 km a tiahne sa popod Alpy. Jeho výstavba trvala 17 rokov.",
      "Všetky gondoly v talianskych Benátkach musia byť natreté čiernou farbou. Tak im to ukladá zákon.",
      "Priemerný taliansky zamestnanec pracuje 20 hodín týždenne. Teda 960 hodín ročne. Toto číslo je najnižšie spomedzi všetkých krajín Európy.",
    ],
    nl: [
      "S priemernou výškou 184 cm u mužou a 170 cm u žien, sú Holanďania najvyšším národom na svete. . Vedci tvrdia, že je to kôli ich DNA, výžive a zabezpečeniu.",
      "Keďže amsterdamská pôda pozostáva z hrubej vrstvy močiarov a hliny, všetky budovy sú postavené na drevených pilieroch, zasadených do hĺbky až 11 metrov. Taký Kráľovský palác stojí na 13 659 pilieroch.",
      "Po Škandinávii spotrebujú Holanďania najväčšie množstvo kávy. Priemerne vypijú až 140 litrov ročne, čo je 3,2 šálky denne na jedného obyvateľa.",
      "Všetky holandské deti sa učia povinne angličtinu v škole a návštevníci Amsterdamu sú častokrát prekvapení plynulosťou,  ktorou Holanďania hovoria anglicky.",
    ],
    ru: [
      "Rusko a Amerika sú v najužšom bode vzdialené len 4 KM.",
      "Bývalý ruský prezident Gorbačov nahral hudobný album plný ruských romantických balád.",
      "Nielen alkohol je veľmi obľúbeným nápojom Ruského národa, ale aj čaj. Tuhá zima sa nerieši iba tvrdým alkoholom, ale aj poriadne horúcim čajom, čo je úplne",
      "Až tretina Rusov verí, že sa Slnko točí okolo Zeme.",
    ],
    pl: [
      "Poľský jazyk používa sedem pádov, čo dáva veľmi zložitú gramatiku. Má tiež veľmi zložitú výslovnosť a mnoho výnimiek z každého jedného pravidla. Ďalším problémom je pravopis, pretože niekedy existujú dva znaky, ktoré označujú ten istý zvuk.",
      "90 % mladých ľudí v Poľsku má stredoškolské vzdelanie a 50 % má akademickú hodnosť.",
      "Poľské jedlo je absolútne vynikajúce! Gołąbki, golonka, żurek a kotlet schabowy vám vyrazia dych, ale poľské jedlo, ktoré MUSÍTE ochutnať, sa volá pierogi!",
      "Vodka ako tradičný poľský alkohol Takmer každý Poliak raz vyskúšal vodku. Dlhé roky to bol najobľúbenejší alkohol podávaný na všetkých svadbách, narodeninových oslavách a výročiach.",
    ],
  },

  pl: {
    en: [
      "W Anglii żyje ponad 55 milionów ludzi.",
      'Z Anglii do Francji można przejechać podwodnym tunelem. Tunel został oddany do użytku w 1994 roku i nosi nazwę "Eurotunel". Jego długość wynosi 50,45 km',
      "W Londynie jest ponad 300 różnych muzeów – zwiedzanie ich zajmie więc co najmniej miesiąc czasu ale i znacznie więcej.",
      "“How are you?” Nie jest pytaniem samym w sobie, ale wstępem do rozmowy – nikt nie oczekuje innej odpowiedzi niż “dziękuje a Ty”.",
    ],
    es: [
      "Nazwa kraju Hiszpania wywodzi się od słowa Ispania, które oznacza Ziemię Królików.",
      "Hiszpania to największy producent bananów w Europie.",
      "Z Hiszpanii pochodzą kultowe lizaki chupa chups (nazwa pochodzi od hiszpańskiego czasownika chupar, który oznacza ssać). Istnieją już od ponad 56 lat.",
      "W Madrycie średnio 200 na 365 dni w roku jest słonecznych.",
    ],
    de: [
      "Niemcy są najludniejszym członkiem Unii Europejskiej z ponad 83 milionami mieszkańców.",
      "Każdego roku w Niemczech spożywa się 800 milionów currywurst. W Berlinie znajduje się nawet muzeum poświęcone currywurst.",
      "Niemcy byli historycznie wielkimi wynalazcami, np. tutaj zbudowano pierwszy samochód, stworzono aspirynę, wydrukowano prasę, rower, mp3 czy wiertarkę.",
      "Niemcy to jeden z największych producentów samochodów na świecie. Jest to również siedziba jednych z najbardziej prestiżowych marek samochodowych na świecie, takich jak Volkswagen, Mercedes. Audi i BMW.",
    ],
    fr: [
      "Większość z listy 50 najdroższych win świata pochodzi z Francji. Najdroższe z nich kosztuje 16 509 euro.",
      "Rząd francuski podarował USA Statuę Wolności. Została zbudowana we Francji i następnie przewieziona statkiem za Atlantyku. Na potrzeby transportu rozebrano ją na 350 elementów.",
      "Luwr to najczęściej odwiedzane muzeum na świecie. Co roku przyjeżdża do niego prawie 10 mln odwiedzających.",
      "Lista francuskich wynalazków jest długa. Znajdują się na niej m.in. łódź podwodna, spadochron, balon, kalkulator.",
    ],
    it: [
      "Wszystkie włoskie słowa kończą się samogłoską: i, a, e, o, u.",
      "We Włoszech wynaleziono m.in. termometr, telefon, baterie, lody, wodę kolońską, okulary.",
      "Ponad połowa Włochów w wieku 18-34 lat mieszka z rodzicami. Wielu Włochów zostaje w domu rodzinnym nawet do 40 roku życia.",
      "Ser parmezan pochodzi z obszaru wokół Parmy we Włoszech. Włosi stworzyli również wiele innych serów, w tym gorgonzola czy mozzarella.",
    ],
    nl: [
      "Holandia posiada najwyższy poziom znajomości języka angielskiego na świecie. Dziewięć na dziesięć osób w kraju mówi po angielsku.",
      "Jest to pierwszy na świecie kraj legalizujący małżeństwo osób tej samej płci od 2001 roku.",
      "Holendrzy to najwyżsi mężczyźni na świecie o średniej wysokości 183 cm.",
      "Vincent Van Gough był znanym holenderskim malarzem. W Amsterdamie znajduje się muzeum poświęcone słynnemu malarzowi.",
    ],
    ru: [
      "W Rosji jest 9 stref czasowych. Pierwotnie było ich 11. W 2010 rząd wprowadził zmiany i zredukował ich liczbę do obecnej.",
      "Rosyjskie lasy nazywane są płucami Europy. Stanowią około 60% powierzchni kraju i ustępują swymi rozmiarami i zdolnością absorpcji dwutlenku węgla tylko lasom amazońskim.",
      "Najdłuższą linie kolejowa na świecie znajduje się w Rosji. Długość linii kolei Transsyberyjskiej wynosi 9200 kilometrów.",
      "Sankt Petersburg trzykrotnie zmieniał nazwę. Na początku XX wieku zwano go Petrogradem, w okresie ZSRR znany był jako Leningrad a od 1991 roku znany jest jako Sankt Petersburg.",
    ],
    pt: [
      "Jednym z najpopularniejszych miejsc na świecie odnośnie surfowania jest Portugalia. Można tutaj surfować 364 dni w roku.",
      "Dąb korkowy pochodzi z Portugalii. Ze względu na różnorodne zastosowania, kraj ten jest największym producentem wyrobów z korka na świecie.",
      "Portugalia jest najstarszym krajem w Europie. Od 1139 r. ma te same określone granice. Nie dziwi więc fakt, że język portugalski jest oficjalnym językiem 9 krajów, a na całym świecie mówi nim ponad 250 milionów ludzi.",
      "Kraj ten ma najdłuższy most w Europie. Most Vasco da Gama w Lizbonie ma długość 17 km.",
    ],
  },

  en: {
    en: [
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
    ],
    es: [
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
    ],
    de: [
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
    ],
    fr: [
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
    ],
    it: [
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
    ],
    nl: [
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
    ],
    ru: [
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
    ],
    pl: [
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
    ],
  },
  ro: {
    en: [
      "În Anglia locuiesc peste 55 de milioane de persoane.",
      'Puteți călători din Anglia în Franța printr-un tunel subacvatic. Tunelul a fost pus în funcțiune în 1994 și se numește "Eurotunel". Lungimea sa este de 50,45 km',
      "În Londra există peste 300 de muzee diferite - așa că va fi nevoie de cel puțin o lună de zile pentru a le vizita, dar și de mult mai mult.",
      '"Ce mai faci?" Nu este o întrebare în sine, ci un început de conversație - nimeni nu se așteaptă la un alt răspuns decât "mulțumesc și dumneavoastră".',
    ],
    es: [
      "Numele țării Spania provine de la cuvântul Ispania, care înseamnă Țara iepurilor.",
      "Spania este cel mai mare producător de banane din Europa.",
      "Spania este țara de origine a iconicelor acadele chupa chups (numele provine de la verbul spaniol chupar, care înseamnă a suge). Acestea există de peste 56 de ani.",
      "La Madrid, în medie 200 din 365 de zile pe an sunt însorite.",
    ],
    de: [
      "Germania este cel mai populat stat membru al Uniunii Europene, cu peste 83 de milioane de locuitori.",
      "În fiecare an, în Germania se consumă 800 de milioane de currywursts. Există chiar și un muzeu dedicat currywurst-ului în Berlin.",
      "Din punct de vedere istoric, germanii au fost mari inventatori, de exemplu, prima mașină a fost construită aici, aspirina a fost creată aici, presa, bicicleta, mp3-ul sau burghiul au fost tipărite aici.",
      "Germania este unul dintre cei mai mari producători de automobile din lume. De asemenea, aici se află unele dintre cele mai prestigioase mărci auto din lume, precum Volkswagen, Mercedes. Audi și BMW.",
    ],
    fr: [
      "Majoritatea listei celor mai scumpe 50 de vinuri din lume provin din Franța. Cel mai scump costă 16 509 euro.",
      "Guvernul francez a donat Statuia Libertății Statelor Unite. A fost construită în Franța și apoi transportată cu vaporul peste Atlantic. Acesta a fost dezasamblat în 350 de bucăți pentru transport.",
      "Luvrul este cel mai vizitat muzeu din lume. Acesta primește aproape 10 milioane de vizitatori în fiecare an.",
      "Lista invențiilor franceze este lungă. Acesta include un submarin, o parașută, un balon și un calculator, printre altele.",
    ],
    it: [
      "Toate cuvintele italiene se termină cu o vocală: i, a, e, o, u.",
      "Italia a inventat termometrul, telefonul, bateriile, înghețata, apa de colonie, ochelarii, printre altele.",
      "Mai mult de jumătate dintre italienii cu vârste cuprinse între 18 și 34 de ani locuiesc cu părinții lor. Mulți italieni rămân în casa familiei chiar și până la vârsta de 40 de ani.",
      "Brânza parmezan este originară din zona din jurul orașului Parma, Italia. Italienii au creat și multe alte brânzeturi, printre care gorgonzola sau mozzarella.",
    ],
    nl: [
      "Țările de Jos au cel mai ridicat nivel de cunoaștere a limbii engleze din lume. Nouă din zece locuitori ai țării vorbesc engleza.",
      "Este prima țară din lume care legalizează căsătoria între persoane de același sex din 2001.",
      "Bărbații olandezi sunt cei mai înalți din lume, cu o înălțime medie de 183 cm.",
      "Vincent Van Gough a fost un celebru pictor olandez. În Amsterdam există un muzeu dedicat celebrului pictor.",
    ],
    ru: [
      "În Rusia există 9 fusuri orare. Inițial au fost 11. În 2010, guvernul a făcut modificări și a redus numărul la cel actual.",
      "Pădurile din Rusia sunt numite plămânii Europei. Acestea reprezintă aproximativ 60% din suprafața țării și sunt pe locul al doilea după pădurea amazoniană în ceea ce privește mărimea și capacitatea de absorbție a carbonului.",
      "Cea mai lungă linie de cale ferată din lume se află în Rusia. Lungimea căii ferate transsiberiene este de 9200 de kilometri.",
      "Sankt Petersburg și-a schimbat numele de trei ori. La începutul secolului al XX-lea se numea Petrograd, în perioada URSS a fost cunoscut sub numele de Leningrad, iar din 1991 este cunoscut sub numele de Sankt Petersburg.",
    ],
    pt: [
      "Una dintre cele mai populare destinații de surfing din lume este Portugalia. Aici se poate face surf 364 de zile pe an.",
      "Stejarul de plută este originar din Portugalia. Țara este cel mai mare producător de produse din plută din lume datorită diverselor sale aplicații.",
      "Portugalia este cea mai veche țară din Europa. Acesta are aceleași granițe definite încă din 1139. Nu este de mirare că portugheza este limba oficială a nouă țări și este vorbită de peste 250 de milioane de persoane din întreaga lume.",
      "Țara are cel mai lung pod din Europa. Podul Vasco da Gama din Lisabona are o lungime de 17 km.",
    ],
  },
  hu: {
    en: [
      "A londoni metró, a világ legrégebbi metrója, egy egész világ önmagában. A londoniak „tube”-nak hívják - „cső”. A metróra általános angol kifejezés a „underground”, azaz az „aluljáró”.",
      "Az angolok a világon a legtöbb teát iszik, majdnem háromszor annyit, mint a japánok, és 22-szer annyit, mint például a franciák.",
      "Anglia fővárosa, London korábban Londonium, Ludenwic, Ludenburg volt.",
      "Angliában több mint 30 000 ember él John Smith néven.",
    ],
    es: [
      "A spanyol a második leggyakrabban beszélt nyelv a kínai után a világon. Összesen több mint 406 millióan beszélik világszerte.",
      "A Chupa Chups cukorkák világszerte híresek, és 1958-ban egy spanyol, Enric Bernat találta fel őket. Eredetileg „Gol” néven tervezték őket, de ez a név nem ragadt rájuk. Később kapta meg a cukorka a „Chups” nevet.",
      "A világ legrégebbi étterme Madridban található, és 1725 óta működik. A neve Sobrino de Botin, és ott olcsóbban lehet enni, mint Prágában.",
      "A világ legrégebbi meglévő világítótornyot Spanyolországban található, és a neve a Héraklész-torony. Az első században épült, és még mindig működik. Galícia területén található, Santiago de Compostela városa közelében.",
    ],
    de: [
      "Berlin fővárosa 9-szer nagyobb, mint Párizs, és sokkal több híddal rendelkezik, mint Velence. Ráadásul Európa legnagyobb vasútállomásával is büszkélkedhet.",
      "Nincs kétség afelől, hogy a németek kreatívak. Ők találták ki az automatikus számológépet, az autót, az üzemanyagot, az inzulint, a zsebórákat, a televíziót, a parafint, a benzines és dízel motort, valamint azt, amit legjobban szeretünk - a gumimacikat!",
      "Németországban az állami hatóságoknak van utolsó szavuk a gyermeknevek kiválasztásakor. Az olyan különleges neveket, amelyek nem mutatnak nemet (például Matti), vagy amelyek helyettesíthetik a keresztnevet a vezetéknévvel, nem fogadják el.",
      "Németország az első ország volt, amely bevezette a nyári időszámítást. Már 1916-ban áttértek rá az elektromos energia megtakarítása érdekében az első világháború idején.",
    ],
    fr: [
      "Párizs leglátogatottabb látnivalója nem az Eiffel-torony, sem a Louvre, hanem a Disneyland.",
      "A francia törvény szerint lehetséges házasságot kötni egy halott személlyel. Bizonyos szabályokat kell betartani, például az érintettnek életében már terveznie kellett ezt.",
      "Franciaországban található Európa legmagasabb hegye - a Mont Blanc (4810 m).",
      "A bikinik is Franciaországból származnak, eredeti nevük 'Atom' volt, majd átnevezték őket egy olyan sziget után, amelyet korábban atomrobbantások tesztelésére használtak, a Bikini-atoll után.",
    ],
    it: [
      "Olaszországban két független állam található ugyanabban az országban: San Marino Köztársaság (40 négyzetkilométer) és a Vatikán (108,7 hektár).",
      "Európában nincs más olyan ország, mint Olaszország, amely annyi vulkánt tartalmaz. Ennek az az oka, hogy az olasz félsziget egy törésvonalon fekszik. Három fő vulkán, az Etna, Stromboli és Vesuvius az utóbbi 100 évben aktív volt.",
      "Sok olasz gyerek él szüleivel még 30 éves koráig is, még akkor is, ha dolgoznak. Az olasz családot az olasz társadalom szívének tekintik.",
      "Közép-Olaszországban található egy szökőkút, amelyből 24 órán keresztül folyik a vörösbor. A bor ingyenes mindenki számára, kivéve a részegeseket és a durva embereket.",
    ],
    nl: [
      "A hollandok a világon a legmagasabbak. Lehet, hogy ennek oka a tejtermékek gyakori fogyasztása.",
      "A Hollandse nieuwe, azaz a friss holland hering hagymával egy holland étel. Bár nem néz ki túl vonzónak, lehet, hogy meglepő lesz az íze.",
      "Hollandia a Holland Németország középső-nyugati részének a neve, és néha helytelenül használják az egész ország nevének.",
      "Hollandiában a világon a legnagyobb a kávéfogyasztás lakosonként, átlagosan napi 2,4 csésze kávét isznak egy főre számítva.",
    ],
    ru: [
      "Oroszország nagyobb, mint Plútó. Plútó 16,6 millió négyzetkilométer területű, míg Oroszország 17 millió négyzetkilométeres.",
      "Oroszországban legalább 15 titkos város található. Nincsenek táblák, közlekedési jelzések, és idegeneket nem engednek közel. Majdnem senki sem ismeri a neveiket vagy pontos elhelyezkedésüket.",
      "Az oroszok 25% -a 55 év előtt meghal, míg az USA-ban csak 1%. A vodka felelős ezért.",
      "A gazdag oroszok az ambulanciát választják a taxis helyett, hogy elkerüljék a moszkvai forgalmat.",
    ],
    pl: [
      "A lengyelek több mint egyharmada nem él Lengyelországban. Bár Lengyelországnak körülbelül 38 millió lakosa van, az egész világon akár 60 millióan is vallják magukat lengyelnek.",
      "Ha a lengyelek valakire büszkék lehetnek, akkor azok bizonyára azok a személyek, akik a rangos Nobel-díjat nyerték el. Nagyon lenyűgöző, hogy az ország egész története alatt összesen 17 személyiségnek sikerült elnyernie a díjat!",
      "Lengyelországban több mint 7 ezer tó található.",
      "A lengyelországi villamosenergia túlnyomórészt hőerőművekben készül, ahol szén égetésére kerül sor.",
    ],
    pt: [
      "Az egyik legnépszerűbb szörfhely a világon Portugália. Itt lehet szörfözni 364 nap a évben.",
      "A parafa tölgy Portugáliából származik. Különböző felhasználásai miatt ez az ország a világ legnagyobb parafa termékeinek gyártója.",
      "Portugália Európa legrégebbi országa. 1139 óta ugyanazokkal a meghatározott határokkal rendelkezik. Nem meglepő, hogy a portugál nyelv hivatalos nyelv 9 országban, és világszerte több mint 250 millió ember beszéli.",
      "Ez az ország rendelkezik Európa leghosszabb hídjával. A Vasco da Gama híd Lisszabonban 17 km hosszú.",
    ],
  },
  hr: {
    en: [
      "Londonški metro, najstarije metro na svijetu, je svijet za sebe. Londonci mu kažu 'tube' - 'cev'. Uobičajeni engleski izraz za metro je 'underground', odnosno podzemna željeznica.",
      "Englezi popiju najviše čaja na svijetu, gotovo tri puta više nego Japanci i 22 puta više nego primjerice Francuzi.",
      "Glavni grad Engleske, London, ranije se zvao Londonium, Ludenwic, Ludenburg.",
      "U Engleskoj živi više od 30.000 ljudi s imenom John Smith.",
    ],
    es: [
      "Španjolski jezik je drugi najrasprostranjeniji jezik na svijetu, odmah iza kineskog. Ukupno ga govori više od 406 milijuna ljudi diljem svijeta.",
      "Lizalke Chupa Chups su svjetski poznate i izumio ih je Španjolac Enric Bernat 1958. godine. Izvorno su se trebale zvati 'Gol', ali taj naziv nije zaživio. Zatim su dobile naziv 'Chups'.",
      "Najstariji restoran na svijetu nalazi se u Madridu i otvoren je od 1725. godine. Njegovo ime je Sobrino de Botin i tamo možete jeftinije jesti nego u Pragu.",
      "Najstariji postojeći svjetionik na svijetu nalazi se u Španjolskoj i zove se Herkulova kula. Izgrađen je u prvom stoljeću i još uvijek je funkcionalan. Možete ga pronaći u regiji Galicija, blizu grada Santiago de Compostela.",
    ],
    de: [
      "Glavni grad Berlin je 9 puta veći od Pariza i ima puno više mostova od Venecije. Ponosi se i najvećim željezničkim kolodvorom u Europi.",
      "Nema sumnje da su Nijemci 'glavice'. Izmislili su automatski kalkulator, automobil, benzin, inzulin, džepni sat, televiziju, parafin, benzinske i dizelske motore, i ono što najviše volimo - gumene medvjediće!",
      "Njemačke vlasti imaju posljednju riječ pri odabiru imena za vaše dijete. Posebna imena koja ne ukazuju na spol (npr. Matti) ili prezimena umjesto prvog imena neće biti odobrena.",
      "Njemačka je prva zemlja koja je uvela tzv. ljetno vrijeme (Daylight Saving Time), tj. pomicanje sata. Prešla je na to već 1916. godine radi uštede električne energije tijekom Prvog svjetskog rata.",
    ],
    fr: [
      "Najposjećenija atrakcija u Parizu nije Eiffelov toranj, niti Louvre, već Disneyland.",
      "Prema francuskom zakonu možete stupiti u brak s mrtvom osobom. Potrebno je ispuniti određena pravila, kao što je, na primjer, da je dotična osoba to planirala za svojeg života.",
      "U Francuskoj ćete pronaći najvišu planinu Europe - Mont Blanc (4810m).",
      "Bikiniji također potječu iz Francuske, izvorno su se zvale Atom, zatim su preimenovane prema otoku nazvanom Bikini, koji je nekad služio za testiranje atomske bombe.",
    ],
    it: [
      "U Italiji postoje dva neovisna grada unutar jedne zemlje: Republika San Marino (40 četvornih kilometara) i Vatikan (108,7 jutara).",
      "Nijedna druga zemlja u Europi nema toliko vulkana kao Italija. To je zbog toga što se talijanski poluotok nalazi na lomnoj liniji. Tri glavna vulkana, Etna, Stromboli i Vezuv, bila su aktivna u posljednjih 100 godina.",
      "Mnoga talijanska djeca žive s roditeljima do svoje 30. godine, čak i ako imaju posao. Talijanska obitelj smatra se srcem talijanskog društva.",
      "U srednjoj Italiji postoji fontana iz koje teče crno vino 24 sata dnevno. Vino je besplatno za sve, osim pijanaca i nepristojnih osoba.",
    ],
    nl: [
      "Nizozemci su najviši na svijetu. Možda je to zbog česte konzumacije mliječnih proizvoda.",
      "Hollandse nieuwe, sirovi sled s lukom, jedno je od nizozemskih jela. Iako ne izgleda primamljivo, možda vas iznenadi.",
      "Holandija je naziv za srednjezapadni dio Nizozemske i ponekad se pogrešno koristi kao naziv za cijelu zemlju.",
      "U Nizozemskoj je najveća potrošnja kave po stanovniku na svijetu, prosječno 2,4 šalice kave po osobi dnevno.",
    ],
    nl: [
      "Nizozemci su najviši na svijetu. Možda je to zbog česte konzumacije mliječnih proizvoda.",
      "Hollandse nieuwe, odnosno sirovi sled s lukom, jedno je od holandskih jela. Iako ne izgleda previše privlačno, možda će vas iznenaditi.",
      "Holandija je naziv za srednjezapadni dio Nizozemske i ponekad se pogrešno koristi kao naziv za cijelu zemlju.",
      "U Nizozemskoj imaju najveću potrošnju kave po stanovniku na svijetu, prosječno 2,4 šalice kave po osobi dnevno.",
    ],
    ru: [
      "Rusija je veća od Plutona. Pluto ima 16,6 milijuna četvornih kilometara, a Rusija 17.",
      "U Rusiji postoji najmanje 15 tajnih gradova. Tamo nema natpisa, prometnih znakova i stranci nisu dopušteni u njihovoj blizini. Gotovo nitko ne zna njihova imena ni točnu lokaciju.",
      "25 % Rusa umire prije nego što dođu do dobi od 55 godina, u usporedbi s samo 1 % u SAD-u. Krivac je votka.",
      "Bogati Rusi umjesto taksija koriste sanitetska vozila kako bi izbjegli moskovsku gužvu.",
    ],
    pl: [
      "Više od trećine Poljaka živi izvan Poljske. Iako Poljska ima oko 38 milijuna stanovnika, čak 60 milijuna ljudi diljem svijeta se identificira kao Poljaci.",
      "Ako se Poljaci mogu ponositi nečim, onda su to svakako osobnosti koje mogu ponosno nositi prestižnu Nobelovu nagradu. Zaista je impresivno da ju je tijekom svoje povijesti osvojilo ukupno 17 osobnosti!",
      "U Poljskoj se nalazi više od 7 tisuća jezera.",
      "Većina električne energije u Poljskoj proizvodi se u termoelektranama gdje dolazi do izgaranja ugljena.",
    ],
  },
  lt: {
    en: [
      "Londono metro, visų pirma pasaulio metro, yra pasaulis pats savaime. Londone jis vadinamas „tube“ – „vamzdis“. Įprastas anglų žodis metro yra „underground“, tai yra požeminė.",
      "Anglai išgeria daugiausia arbatos pasaulyje, beveik tris kartus daugiau nei japonai ir 22 kartus daugiau nei pvz., prancūzai.",
      "Anglijos sostinė Londas anksčiau buvo vadinama Londonium, Ludenwic, Ludenburg",
      "Anglijoje gyvena daugiau nei 30 000 žmonių su vardu John Smith.",
    ],
    es: [
      "Ispanų kalba, antra pagal paplitimą pasaulyje po kinų kalbos. Visame pasaulyje ja kalba daugiau nei 406 milijonai žmonių.",
      "Chupa Chups saldainiai yra pasaulinio garso ir buvo išrasti ispano Enrico Bernato 1958 m. Pradžioje jie turėjo vadintis „Gol“, tačiau šis pavadinimas nepriėmėsi. Vėliau saldainiai gavo pavadinimą „Chups“.",
      "Seniausias restoranas pasaulyje yra Madriduje ir yra atidarytas nuo 1725 metų. Jo pavadinimas yra Sobrino de Botin ir ten valgysite pigiau nei Prahos.",
      "Seniausias egzistuojantis švyturys pasaulyje yra Ispanijoje ir vadinasi Herkulovo bokštas. Jis pastatytas pirmajame amžiuje ir vis dar veikia. Jį rasite Galiсijos regione, netoli miesto Santiago de Compostela.",
    ],
    de: [
      "Vokietijos sostinė Berlynas yra 9 kartus didesnis nei Paryžius ir turi daugiau tiltų nei Venecija. Ji taip pat didžiuojasi didžiausiu traukinių stotimi Europoje.",
      "Kad vokiečiai yra „protėviški“, abejonių nėra. Jie išrado automatinį skaičiuotuvą, automobilį, benzinas, insuliną, kišeninius laikrodžius, televizorių, parafiną, benzino ir dyzelino variklius ir tai, ko mes labiausiai mėgstame - kramtomoji guma!",
      "Vokietijos valdžios institucijos turi paskutinį žodį renkantis vardą jūsų kūdikiui. Specialūs vardai, nesukuriantys giminės (pvz., Matti), arba pavardė vietoje vardo nebus patvirtinti.",
      "Vokietija yra pirmoji šalis, kuri įdiegė taip vadinamą vasaros laiką. Ji jį perėjo jau 1916 metais siekdama sutaupyti elektros energijos Pirmojo pasaulinio karo metu.",
    ],
    fr: [
      "Lankyčiausia atrakcija Paryžiuje nėra Eifelio bokštas ar Louvro, bet Disneylandas.",
      "Pagal Prancūzijos teisę galite susituokti su mirusiu žmogumi. Reikia laikytis tam tikrų taisyklių, pavyzdžiui, kad žmogus tai buvo suplanavęs gyvas.",
      "Prancūzijoje rasite aukščiausią Europos kalną - Mont Blanc (4810 m).",
      "Bikiniai taip pat kilę iš Prancūzijos, originalus pavadinimas buvo „Atom“, po to jie buvo pervadinti pagal salą, vadinamą Bikini, kuri anksčiau buvo naudojama branduolinių bombų bandymams.",
    ],
    it: [
      "Italijoje yra du nepriklausomi valstybės vidaus subjektai: San Marinas Respublika (40 kv. Km) ir Vatikano miestas (108,7 akrai).",
      "Nė viena kita Europos šalis neturi tiek daug ugnikalnių kaip Italija. Tai susiję su tuo, kad italų pusiasalis stovi ant lūžio linijos. Trys pagrindiniai ugnikalniai, Etna, Stromboli ir Vezuvas, buvo aktyvūs per pastaruosius 100 metų.",
      "Daugelis italų vaikų gyvena su savo tėvais iki 30 metų, net jei jie dirba. Italų šeima laikoma Italijos visuomenės širdimi.",
      "Vidurio Italijoje yra fontanas, iš kurio teka raudonas vynas 24 valandas per parą. Vynas yra nemokamas visiems, išskyrus girtuoklius ir šiukšliadėžėms.",
    ],
    nl: [
      "Olandai yra aukščiausi pasaulyje. Tai gali būti dėl dažno pieno produktų vartojimo.",
      "Hollandse nieuwe, tai yra žalias silkė su svogūnais, yra vienas iš olandiškų patiekalų. Nors jis neatrodo labai viliojantis, galbūt jus nustebins.",
      "Nyderlandai yra Pietvakarių Nyderlandų regiono pavadinimas ir kartais klaidingai naudojamas kaip visos šalies pavadinimas.",
      "Nyderlanduose vidutiniškai gėriama daugiausia kavos pasaulyje, per metus vidutiniškai iki 140 litrų, tai yra 3,2 puodeliai per dieną vienam gyventojui.",
    ],
    ru: [
      "Rusija ir Amerika yra atstumu nuo 4 km viena nuo kitos artimiausios taške.",
      "Buvęs Rusijos prezidentas Gorbačiovas įrašė muzikos albumą, pilną rusiškų romantinių baladžių.",
      "Ne tik alkoholis yra labai mėgstamas Rusijos žmonių gėrimas, bet ir arbata. Griežta žiema yra ne tik sparta su tvirtu alkoholiu, bet ir karšta arbata, tai visiškai.",
      "Maždaug trečdalis rusų tiki, kad Saule sukasi aplink Žemę.",
    ],
    pl: [
      "Lenkų kalba naudoja septynis linksnius, tai suteikia labai sudėtingą gramatiką. Ji taip pat turi labai sudėtingą tarimą ir daugybę išimčių nuo kiekvieno taisyklės.",
      "90% jaunimo Lenkijoje turi vidurinį išsilavinimą, o 50% turi akademinį laipsnį.",
      "Lenkiška virtuvė yra absoliučiai puiki! Gołąbki, golonka, żurek ir kotlet schabowy jus palinksmins, bet lenkiška maisto, kurį PRIVALOTE paragauti, vadinama pierogi!",
      "Vodka kaip tradicinis lenkiškas gėrimas. Beveik kiekvienas Lenkas bent kartą ragavo vodką. Ilgus metus tai buvo populiariausias gėrimas, poduodamas visuose vestuvėse, gimtadienių šventėse ir jubiliejų metu.",
    ],
  },
  de: {
    en: [
      "Die Londoner U-Bahn, die erste U-Bahn der Welt, ist eine Welt für sich. In London wird sie „Tube“ genannt – „Röhre“. Das übliche englische Wort für U-Bahn ist „Underground“, also unterirdisch.",
      "Die Engländer trinken weltweit am meisten Tee, fast dreimal so viel wie die Japaner und 22-mal mehr als beispielsweise die Franzosen.",
      "Die Hauptstadt Englands, London, wurde früher Londonium, Ludenwic und Ludenburg genannt.",
      "In England leben mehr als 30.000 Menschen mit dem Namen John Smith.",
    ],
    es: [
      "Spanisch ist nach Chinesisch die zweithäufigste Sprache der Welt. Weltweit sprechen mehr als 406 Millionen Menschen Spanisch.",
      "Chupa Chups Bonbons sind weltberühmt und wurden 1958 vom Spanier Enric Bernat erfunden. Anfangs sollten sie „Gol“ heißen, aber dieser Name setzte sich nicht durch. Später erhielten die Bonbons den Namen „Chups“. ",
      "Das älteste Restaurant der Welt befindet sich in Madrid und ist seit 1725 geöffnet. Es heißt Sobrino de Botín und dort isst man günstiger als in Prag.",
      "Der älteste noch bestehende Leuchtturm der Welt befindet sich in Spanien und heißt Herkulesturm. Er wurde im ersten Jahrhundert erbaut und ist noch in Betrieb. Man findet ihn in der Region Galicien, in der Nähe der Stadt Santiago de Compostela.",
    ],
    de: [
      "Die Hauptstadt Deutschlands, Berlin, ist neunmal größer als Paris und hat mehr Brücken als Venedig. Sie rühmt sich auch des größten Bahnhofs in Europa.",
      "Dass die Deutschen erfinderisch sind, steht außer Zweifel. Sie haben den automatischen Rechner, das Auto, Benzin, Insulin, Taschenuhren, das Fernsehen, Paraffin, Benzin- und Dieselmotoren und das, was wir am meisten lieben - Kaugummi - erfunden!",
      "Die deutschen Behörden haben das letzte Wort bei der Wahl des Namens für Ihr Baby. Ungewöhnliche Namen, die kein Geschlecht anzeigen (z.B. Matti) oder Nachnamen als Vornamen werden nicht akzeptiert.",
      "Deutschland war das erste Land, das die sogenannte Sommerzeit eingeführt hat. Dies geschah bereits 1916, um während des Ersten Weltkriegs Strom zu sparen.",
    ],
    fr: [
      "Die meistbesuchte Attraktion in Paris ist nicht der Eiffelturm oder der Louvre, sondern Disneyland.",
      "Nach französischem Recht können Sie eine verstorbene Person heiraten. Es müssen bestimmte Regeln eingehalten werden, zum Beispiel, dass die Person dies zu Lebzeiten geplant hatte.",
      "In Frankreich finden Sie den höchsten Berg Europas - den Mont Blanc (4810 m).",
      "Bikinis stammen ebenfalls aus Frankreich, ursprünglich hießen sie „Atome“, später wurden sie nach einer Insel namens Bikini umbenannt, die früher für Atomtests genutzt wurde.",
    ],
    it: [
      "In Italien gibt es zwei unabhängige Binnenstaaten: die Republik San Marino (40 km²) und die Vatikanstadt (108,7 Hektar).",
      "Kein anderes europäisches Land hat so viele Vulkane wie Italien. Das liegt daran, dass die italienische Halbinsel auf einer Bruchlinie liegt. Die drei Hauptvulkane, Etna, Stromboli und Vesuv, waren in den letzten 100 Jahren aktiv.",
      "Viele italienische Kinder leben bis zu ihrem 30. Lebensjahr bei ihren Eltern, auch wenn sie arbeiten. Die italienische Familie gilt als Herzstück der italienischen Gesellschaft.",
      "In Mittelitalien gibt es einen Brunnen, aus dem 24 Stunden am Tag Rotwein fließt. Der Wein ist für alle kostenlos, außer für Betrunkene und Müllsammler.",
    ],
    nl: [
      "Die Niederländer sind die größten Menschen der Welt. Dies könnte auf den häufigen Verzehr von Milchprodukten zurückzuführen sein.",
      "Hollandse nieuwe, das ist roher Hering mit Zwiebeln, ist eines der niederländischen Gerichte. Obwohl es nicht sehr verlockend aussieht, wird es Sie vielleicht überraschen.",
      "Die Niederlande sind der Name der südwestlichen Region der Niederlande und werden manchmal fälschlicherweise als Name des ganzen Landes verwendet.",
      "In den Niederlanden wird im Durchschnitt der meiste Kaffee der Welt getrunken, durchschnittlich 140 Liter pro Jahr, das sind 3,2 Tassen pro Tag pro Person.",
    ],
    ru: [
      "Russland und Amerika sind an ihrem nächsten Punkt nur 4 km voneinander entfernt.",
      "Der ehemalige russische Präsident Gorbatschow hat ein Musikalbum mit russischen romantischen Balladen aufgenommen.",
      "Nicht nur Alkohol ist ein sehr beliebtes Getränk in Russland, sondern auch Tee. Der strenge Winter wird nicht nur durch starken Alkohol, sondern auch durch heißen Tee überstanden.",
      "Etwa ein Drittel der Russen glaubt, dass die Sonne sich um die Erde dreht.",
    ],
    pl: [
      "Die polnische Sprache verwendet sieben Fälle, was eine sehr komplizierte Grammatik ergibt. Sie hat auch eine sehr komplexe Aussprache und viele Ausnahmen von jeder Regel.",
      "90% der jungen Menschen in Polen haben einen Sekundarabschluss, und 50% haben einen akademischen Grad.",
      "Die polnische Küche ist absolut großartig! Gołąbki, golonka, żurek und kotlet schabowy werden Sie begeistern, aber das polnische Gericht, das Sie unbedingt probieren müssen, heißt pierogi!",
      "Wodka ist ein traditionelles polnisches Getränk. Fast jeder Pole hat mindestens einmal Wodka probiert. Viele Jahre lang war es das beliebteste Getränk bei allen Hochzeiten, Geburtstagsfeiern und Jubiläen.",
    ],
  },
  it: {
    en: [
      "La metropolitana di Londra, la prima metropolitana del mondo, è un mondo a sé. A Londra viene chiamata 'Tube' - 'tubo'. La parola inglese usuale per metropolitana è 'Underground', cioè sotterranea.",
      "Gli inglesi bevono più tè al mondo, quasi tre volte più dei giapponesi e 22 volte più dei francesi, per esempio.",
      "La capitale dell'Inghilterra, Londra, era precedentemente chiamata Londinium, Ludenwic e Ludenburg.",
      "In Inghilterra vivono più di 30.000 persone con il nome John Smith.",
    ],
    es: [
      "Lo spagnolo è la seconda lingua più parlata al mondo dopo il cinese. Più di 406 milioni di persone parlano spagnolo in tutto il mondo.",
      "Le caramelle Chupa Chups sono famose in tutto il mondo e sono state inventate dallo spagnolo Enric Bernat nel 1958. All'inizio dovevano chiamarsi 'Gol', ma questo nome non prese piede. Successivamente le caramelle ricevettero il nome 'Chups'.",
      "Il ristorante più antico del mondo si trova a Madrid ed è aperto dal 1725. Si chiama Sobrino de Botín e si mangia più economicamente che a Praga.",
      "Il faro più antico ancora esistente del mondo si trova in Spagna e si chiama Torre di Ercole. È stato costruito nel primo secolo ed è ancora in funzione. Si trova nella regione della Galizia, vicino alla città di Santiago de Compostela.",
    ],
    de: [
      "La capitale della Germania, Berlino, è nove volte più grande di Parigi e ha più ponti di Venezia. Si vanta anche della stazione ferroviaria più grande d'Europa.",
      "Non c'è dubbio che i tedeschi siano inventivi. Hanno inventato il calcolatore automatico, l'automobile, la benzina, l'insulina, gli orologi da tasca, la televisione, la paraffina, i motori a benzina e diesel e ciò che amiamo di più - il chewing gum!",
      "Le autorità tedesche hanno l'ultima parola nella scelta del nome per il vostro bambino. Nomi insoliti che non indicano un genere (ad esempio Matti) o cognomi usati come nomi di battesimo non sono accettati.",
      "La Germania è stato il primo paese a introdurre l'ora legale. Questo è successo già nel 1916 per risparmiare energia durante la prima guerra mondiale.",
    ],
    fr: [
      "L'attrazione più visitata di Parigi non è la Torre Eiffel o il Louvre, ma Disneyland.",
      "Secondo la legge francese, è possibile sposare una persona deceduta. Bisogna rispettare alcune regole, ad esempio che la persona lo avesse pianificato in vita.",
      "In Francia si trova la montagna più alta d'Europa - il Monte Bianco (4810 m).",
      "Anche i bikini provengono dalla Francia, originariamente si chiamavano 'Atome', poi sono stati rinominati in Bikini, da un'isola utilizzata per test atomici.",
    ],
    it: [
      "In Italia ci sono due stati indipendenti: la Repubblica di San Marino (40 km²) e la Città del Vaticano (108,7 ettari).",
      "Nessun altro paese europeo ha tanti vulcani quanto l'Italia. Questo perché la penisola italiana si trova su una linea di faglia. I tre principali vulcani, Etna, Stromboli e Vesuvio, sono stati attivi negli ultimi 100 anni.",
      "Molti bambini italiani vivono con i loro genitori fino a 30 anni, anche se lavorano. La famiglia italiana è considerata il cuore della società italiana.",
      "Nell'Italia centrale c'è una fontana da cui scorre vino rosso 24 ore al giorno. Il vino è gratuito per tutti, tranne che per gli ubriachi e i raccoglitori di rifiuti.",
    ],
    nl: [
      "Gli olandesi sono le persone più alte del mondo. Questo potrebbe essere dovuto al frequente consumo di latticini.",
      "L'hollandse nieuwe, cioè aringa cruda con cipolle, è uno dei piatti olandesi. Anche se non sembra molto invitante, potrebbe sorprenderti.",
      "Olanda è il nome della regione sud-occidentale dei Paesi Bassi e viene talvolta erroneamente usato per indicare l'intero paese.",
      "Nei Paesi Bassi si consuma in media la maggior quantità di caffè al mondo, in media 140 litri all'anno, cioè 3,2 tazze al giorno per persona.",
    ],
    ru: [
      "La Russia e l'America sono nel loro punto più vicino solo a 4 km di distanza.",
      "L'ex presidente russo Gorbaciov ha registrato un album musicale con ballate romantiche russe.",
      "Non solo l'alcol è una bevanda molto popolare in Russia, ma anche il tè. Il rigido inverno viene superato non solo con alcolici forti, ma anche con tè caldo.",
      "Circa un terzo dei russi crede che il sole giri intorno alla Terra.",
    ],
    pl: [
      "La lingua polacca usa sette casi, il che rende la grammatica molto complicata. Ha anche una pronuncia molto complessa e molte eccezioni a ogni regola.",
      "Il 90% dei giovani in Polonia ha un diploma di scuola secondaria e il 50% ha una laurea.",
      "La cucina polacca è assolutamente fantastica! Gołąbki, golonka, żurek e kotlet schabowy vi delizieranno, ma il piatto polacco che dovete assolutamente provare si chiama pierogi!",
      "La vodka è una bevanda tradizionale polacca. Quasi ogni polacco ha provato la vodka almeno una volta. Per molti anni è stata la bevanda più popolare in tutti i matrimoni, compleanni e anniversari.",
    ],
  }
};

function getLanguageFunFact() {
  const dayOfMonth = new Date().getDate();
  const langFunFacts = languageFunFacts[Store.getUserPlatformLangShort()];
  const langShortFunfact =
    Object.keys(langFunFacts)[dayOfMonth % Object.keys(langFunFacts).length];
  const funFacts = langFunFacts[langShortFunfact];
  return {
    funfact: funFacts[dayOfMonth % 4],
    map: getLanguageMap(langShortFunfact),
  };
}

export default getLanguageFunFact;
