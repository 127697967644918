import styled from "styled-components";

export const Cloud = styled.div`
  background-image: ${({ img }) => `url(${img})`};
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0px 30px 0px 10px;

`;


export const BackgroundProductPage = styled.div`
  background-image: ${({ img }) => `url(${img})`};
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
`;
export const ColorChangerProductPage = styled.div`
  background: rgba(85, 159, 168, 0.78);
  min-height: 100vh;
  display: grid;
`;
export const Background = styled.div`
  background-image: ${({ img }) => `url(${img})`};
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 35vh;
`;
export const ColorChanger = styled.div`
  background: rgba(85, 159, 168, 0.78);
  min-height: 35vh;
  display: flex;
  justify-content: center;
`;