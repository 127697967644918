import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";

import Gabor from "./images/Gabor.png";
import Ildiko from "./images/Ildiko.png";
import Katalin from "./images/Katalin.png";
import Maria from "./images/Maria.png";
import Zoltan from "./images/Zoltan.png";
import Istvan from "./images/Istvan.png";

import Slider from "react-slick";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WhitePart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 0 30px;

  @media (max-width: 600px) {
    margin: 40px 30px 0 30px;
  }
`;

const BluePart = styled.div`
  max-width: 1200px;
  padding: 30px 30px;
  margin-bottom: 20px;
  gap: 40px;

  @media (max-width: 1200px) {
    max-width: 900px;
  }

  .slick-slide > div {
    display: grid;
    place-items: center;
    margin: auto;

    @media (min-width: 600px) {
      width: 400px;
    }
  }
`;

const Title = styled.h3`
  font-size: 40px !important;
  color: ${Colors.mainColor};
  font-weight: 900 !important;
  width: 100%;
  text-align: center;

  @media (max-width: 600px) {
    margin-top: -40px;
    text-align: left;
    font-size: 40px !important;
  }
`;
const Subtitle = styled.h4`
  color: ${Colors.black};
  font-size: 16px !important;
`;

const Card = styled.div`
  border-radius: 8px;
  padding: 20px;
  display: flex !important;
  flex-direction: column;
  gap: 20px;
  background: #f5f5f5;
  height: 420px;
  max-width: 360px;

  @media (max-width: 600px) {
    font-size: 18px !important;
    height: 480px;
  }
`;
const CardComment = styled.h4`
  color: ${Colors.black};
  height: 350px;
  font-size: 22px !important;
  font-weight: normal !important;
  font-size: 16px !important;
  max-width: 300px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 18px !important;
    height: 300px;
  }
`;

const CardFlexWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: -60px;
  @media (max-width: 600px) {
    margin-top: -20px;
  }
`;
const CardPhoto = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;
`;
const CardName = styled.h3`
  color: ${Colors.black};
  font-style: italic;
  font-size: 16px !important;
  width: 100px;
  text-align: center;
`;

const TitleCard = styled.h3`
  color: ${Colors.black};
  text-align: center;
  font-size: 16px !important;
  font-weight: 700 !important;
`;

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const SuccessStory = ({ data }) => {
  const slider = useRef();
  const [settings, setSettings] = useState();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const toShow =
      window.innerWidth > 900 ? (window.innerWidth > 1200 ? 3 : 2) : 1;

    setSettings({
      infinite: true,
      speed: 500,
      slidesToShow: toShow,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
      className: "slider-custom",
    });
  }, [slider, window.innerWidth]);

  return (
    <Wrapper id="success-stories">
      <WhitePart>
        <Title>{data.title}</Title>
        <Subtitle>{data.subtitle}</Subtitle>
      </WhitePart>
      <BluePart>
        <Slider {...settings} ref={slider}>
          {data.config.map((element) => (
            <Card>
              <TitleCard>{element.title}</TitleCard>
              <CardComment>{element.comment}</CardComment>
              <CardFlexWrapper>
                <CardPhoto>
                  <img
                    alt="person"
                    src={element.photo}
                    style={{ width: "auto", height: "100%" }}
                  />
                </CardPhoto>
                <CardName>{element.name}</CardName>
              </CardFlexWrapper>
            </Card>
          ))}
        </Slider>
      </BluePart>
    </Wrapper>
  );
};

export default SuccessStory;
